<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">
      <b-col class="d-lg-none d-block py-1 bg-white">
        <b-link class="d-flex justify-content-center">
          <b-img
            src="./../../assets/images/avatars/image.png"
            rounded
            fluid
            alt="profile photo"
            class="bg-white logo_img"
          />
        </b-link>
      </b-col>
      <b-col lg="6" class="d-none d-lg-flex align-items-start mt-0">
        <div class="d-lg-flex justify-content-center sticky-top">
          <div class="mb-5">
            <!-- Brand logo  for large device -->
            <b-link class="brand-logo pb-5">
              <img src="./../../assets/images/logo/vep-logo.png" alt="" />
            </b-link>
          </div>
          <!-- Left Text for large device-->
          <div class="p-5">
            <b-img fluid :src="imgUrl" alt="Register V2" />
          </div>
        </div>
      </b-col>

      <!-- Login-->
      <b-col
        lg="6"
        class="d-flex align-items-center auth-bg px-2 p-lg-5 form overflow-auto"
      >
        <b-col sm="8" md="6" lg="8" class="px-xl-2 pb-5 mx-auto">
          <b-card-title class="mb-1 font-weight-bold" title-tag="h2">
            Welcome to VEP 👋
          </b-card-title>
          <b-card-text class="mb-2"> Sign-In to your account </b-card-text>

          <!-- form -->
          <validation-observer ref="loginForm">
            <b-form class="auth-login-form mt-2" @submit.prevent="handleLogin">
              <!-- email -->
              <b-form-group label="Email" label-for="login-email">
                <validation-provider
                  #default="{ errors }"
                  name="Email"
                  vid="email"
                  rules="required|email"
                >
                  <b-form-input
                    id="login-email"
                    v-model="form.email"
                    :state="errors.length > 0 ? false : null"
                    name="login-email"
                    placeholder="john@example.com"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- forgot password -->
              <b-form-group>
                <div class="d-flex justify-content-between">
                  <label for="login-password">Password</label>
                  <b-link :to="{ name: 'auth-forgot-password' }">
                    <small>Forgot Password?</small>
                  </b-link>
                </div>
                <validation-provider
                  #default="{ errors }"
                  name="Password"
                  vid="password"
                  rules="required"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid' : null"
                  >
                    <b-form-input
                      id="login-password"
                      v-model="form.password"
                      :state="errors.length > 0 ? false : null"
                      class="form-control-merge"
                      :type="passwordFieldType"
                      name="login-password"
                      placeholder="Password"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        class="cursor-pointer"
                        :icon="passwordToggleIcon"
                        @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- checkbox -->
              <b-form-group>
                <b-form-checkbox
                  id="remember-me"
                  v-model="form.status"
                  name="checkbox-1"
                >
                  Remember Me
                </b-form-checkbox>
              </b-form-group>

              <!-- submit buttons -->
              <b-button type="submit" variant="success" block>
                Sign in
              </b-button>
            </b-form>
          </validation-observer>

          <b-card-text class="text-center mt-2">
            <span>New on our platform? </span>
            <b-link :to="{ name: 'auth-register' }">
              <span>&nbsp;Create an account</span>
            </b-link>
          </b-card-text>

          <!-- divider -->
          <div class="divider my-2">
            <div class="divider-text">or</div>
          </div>
          <b-button class="linkedIn__button" block @click="handleLinkedIn">
            Login with LinkedIn
          </b-button>
        </b-col>
      </b-col>
      <!-- /Login-->
      <!-- basic modal -->
      <b-modal
        id="linkedin-modal"
        title="Basic Modal"
        ok-only
        ok-title="Accept"
      >
        {{ linkText }}
      </b-modal>
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import useJwt from "@/auth/jwt/useJwt"
import store from "@/store/index"
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue"
import { togglePasswordVisibility } from "@core/mixins/ui/forms"
import { email, required } from "@validations"
import axios from "@axios"
import {
  BButton,
  BCardText,
  BCardTitle,
  BCol,
  BForm,
  BFormCheckbox,
  BFormGroup,
  BFormInput,
  BImg,
  BInputGroup,
  BInputGroupAppend,
  BLink,
  BRow,
  VBTooltip,
} from "bootstrap-vue"
import { ValidationObserver, ValidationProvider } from "vee-validate"
import { getHomeRouteForLoggedInUser } from "@/auth/utils"
import errorResponse from "@/libs/axiosError"

export default {
  directives: {
    "b-tooltip": VBTooltip,
  },
  components: {
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      form: {
        status: "",
        password: "",
        email: "",
      },
      linkText: null,
      sideImg: require("@/assets/images/pages/login-v2.svg"),
      // validation rules
      required,
      email,
      url: process.env.VUE_APP_BACKEND_BASE,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon"
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === "dark") {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require("@/assets/images/pages/login-v2-dark.svg")
        return this.sideImg
      }
      return this.sideImg
    },
  },
  methods: {
    handleLinkedIn() {
      const urlData = `${process.env.VUE_APP_BACKEND_BASE}social-login/linkedin`
      window.location.href = urlData
    },
    async handleLogin() {
      axios
        .post("/login", this.form)
        .then((res) => {
          if (res.data.error) {
            let errorText = ""
            let errorCount = 0
            // eslint-disable-next-line no-restricted-syntax, no-unused-vars
            for (const [key, value] of Object.entries(res.data.messages)) {
              if (errorCount === 0) {
                errorText += value[0]
              } else {
                errorText += ` & ${value[0]} `
              }
              // eslint-disable-next-line no-plusplus
              errorCount++
            }
            this.$toast({
              component: ToastificationContent,
              position: "top-right",
              props: {
                title: "Something went Wrong!",
                icon: "AlertTriangleIcon",
                variant: "danger",
                text: errorText,
              },
            })
          } else if (res.data?.user?.userData.role.code === "candidate") {
            useJwt.setToken(res.data?.accessToken)
            useJwt.setUserData(res.data?.user?.userData)
            store.dispatch("auth/login", res.data)
            this.$router
              .replace(
                getHomeRouteForLoggedInUser(res.data?.user?.userData.role.code)
              )
              .then(() => {
                this.$toast({
                  component: ToastificationContent,
                  position: "top-right",
                  props: {
                    title: `Welcome ${res.data?.user?.userData?.name}`,
                    icon: "CoffeeIcon",
                    variant: "success",
                    text: `You have successfully logged in as ${res.data?.user?.userData.role.name}. Now you can start to explore!`,
                  },
                })
              })
          } else {
            this.$toast({
              component: ToastificationContent,
              position: "top-right",
              props: {
                title: `Sorry ${res.data?.user?.userData?.name}`,
                icon: "CoffeeIcon",
                variant: "danger",
                text: `You Don't have Permission!`,
              },
            })
          }
        })
        .catch((error) => {
          errorResponse(error, this.$router)
        })
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
// .brand_logo {
//   padding-bottom: -100px !important;
//   height: 50px;
// }
@media only screen and (max-width: 950px) {
  // .form {
  //   margin-top: -100px;
  // }
  // .register {
  //   margin-top: -100px;
  // }
  .logo_img {
    width: 120px !important;
    margin-left: -80px;
  }
}
</style>
